import useBaseUtils from "~/functions/useBaseUtils";
import useDateTimeFormatters from "~/functions/useDateTimeFormatters";
import { defaultUsdObj } from "~/utils/constants/objects";
export default defineComponent({
  props: {
    symbol: {
      type: String,
      "default": "SPY"
    },
    tradingitemid: {
      type: String,
      "default": "6179710"
    },
    hidePrice: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup() {
    var _useBaseUtils = useBaseUtils(),
      dev = _useBaseUtils.dev,
      I18nFn = _useBaseUtils.I18nFn;
    var _useDateTimeFormatter = useDateTimeFormatters(),
      utcEpochToLongDate = _useDateTimeFormatter.utcEpochToLongDate;
    return {
      dev: dev,
      I18nFn: I18nFn,
      utcEpochToLongDate: utcEpochToLongDate,
      defaultUsdObj: defaultUsdObj
    };
  },
  computed: {
    loading: function loading() {
      return this.$store.state.ciq.fetchingQuotes || this.$store.state.iex.SingleStockLoading;
    },
    activeCurrency: function activeCurrency() {
      // return (
      //   this.currencies[this.currencyToggle] ||
      // )
      return this.defaultUsdObj;
    },
    iexPrice: function iexPrice() {
      try {
        return this.$store.state.iex.lastSingleStockQuote[this.tradingitemid] || {};
      } catch (error) {
        return {};
      }
    },
    closeQuotes: function closeQuotes() {
      return this.$store.state.ciq.quotes.price || [];
    },
    last: function last() {
      if (this.closeQuotes.length > 0) {
        var lastDay = this.closeQuotes[this.closeQuotes.length - 1];
        var secondToLast = this.closeQuotes[this.closeQuotes.length - 2]; // const latestPrice = this.currencyToggle
        //   ? lastDay.c / lastDay.pc
        //   : lastDay.c
        var latestPrice = lastDay.c;
        var latestTime = this.utcEpochToLongDate.format(lastDay.d) + this.I18nFn(" Close");
        if (secondToLast) {
          var previousClose = this.currencyToggle ? secondToLast.c / secondToLast.pc : secondToLast.c;
          var change = latestPrice - previousClose;
          var changePercent = change / previousClose; // TODO: Make time formatting better
          return {
            latestPrice: latestPrice,
            change: change,
            changePercent: changePercent,
            latestTime: latestTime
          };
        } else {
          return {
            latestPrice: latestPrice,
            change: 0,
            changePercent: 0,
            latestTime: latestTime
          };
        }
      }
      return {
        latestPrice: 0,
        change: 0,
        changePercent: 0,
        latestTime: ""
      };
    }
  }
});